.swal-title {
	font-size: 27px;
	font-family: "Kenney Future Square";
	color: #000;
	font-weight: normal;
}

.swal-text {
	font-family: "Poppins";
	font-size: 15px;
	color: #000;
}

body {
    font-family: "Poppins";
}

button.swal-button.swal-button--confirm {
	border-color: #4472c7;
	background: #4472c7;
	color: #fff;
	text-decoration: none;
	padding: 12px 10px;
	border-radius: 0;
	display: block;
	width: 30%;
	margin: 0 auto;
}
button.swal-button.swal-button--confirm:hover {
	background: #a8967c;
	border-color: #a8967c;
}
.swal-button:focus {
	box-shadow: none;
}
.swal-button-container {
	margin: 5px;
	display: block;
	position: relative;
	text-align: center;
}

.swal-modal {
	border-radius: 0;
}
.notification-text {
	font-family: "Poppins";
}

.creator-index {
	position: absolute;
	left: 87%;
	top: 0;
	font-size: 19px !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}
.tox-notification tox-notification--in tox-notification--warning {
	display: none;
}

.text-title {
	text-transform: lowercase;
}

.truncate {
	/* text-overflow: ellipsis;

  
  overflow: hidden;
  white-space: nowrap;
  width: 70%; */
	margin-left: 0.5em;
}

.truncate2 {
	text-overflow: ellipsis;

	/* Needed to make it work */
	overflow: hidden;
	white-space: nowrap;
	width: 90%;
	/* margin-left: 0.5em; */
}

.btn-secondary {
	border-radius: 0px !important;
	border: none !important;
	background-color: #e8e9e9 !important;
}

.btn-primary {
	border-radius: 0px !important;
	border: none !important;
}

.a-ell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  line-clamp: 2;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
	background-color: #fff5cc;
	border-color: #fff0b3;
	color: #222f3e;
	display: none;
}

.cropped {
	width: 100%; /* width of container */
	height: 277px; /* height of container */
	overflow: hidden;
	object-fit: cover;
	object-position: center;
}

table tr th {
	text-align: center !important;
}

ul.listing-style-wrap li span.first-col {
	width: 60px;
	font-weight: 600;
	min-width: 60px;
}

ul.listing-style-wrap li span.second-col.justify-content-between img {
	position: absolute;
	right: 20px;
}

.rj-btn:hover {
	background-color: #a8967c !important;
	color: white !important;
	border: none !important
}

.cancel-btn {
	background-color: #a8967c !important;
	color: white !important;
}

